// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  Booking,
  CreateTutoringPayload,
  Nullable,
  Tutor,
  Tutoring
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useTutoringStore = defineStore('tutoring', () => {
  // fetch repositories
  const { $tutorings } = useNuxtApp()

  // state
  const agreeTermsAndConditions = ref(false)
  const selectedTutor = ref<Nullable<Tutor>>(null)
  const pendingBookingsByTutor = ref<{ [tutorId: number]: Booking[]}>({})
  const totalPendingDurationByTutor = ref<{ [tutorId: number]: number }>({})
  const tutoring = ref<Nullable<Tutoring>>(null)
  const tutoringPayloads = ref<{ sessionStartsAt: CreateTutoringPayload }>(
    {} as { sessionStartsAt: CreateTutoringPayload }
  )

  // actions
  async function show (
    id: number | string,
    config?: NitroFetchOptions<string>
  ) {
    const { tutoring: _tutoring } =
      await $tutorings.show<{ tutoring: Tutoring }>(id, config)
    tutoring.value = _tutoring
    return tutoring
  }

  async function create (
    payload?: Record<string, any>,
    config?: NitroFetchOptions<string>
  ) {
    const { tutoring: _tutoring } =
      await $tutorings.create<{ tutoring: Tutoring }>(payload, config)
    tutoring.value = _tutoring
    return tutoring
  }

  async function deleteTutoring (
    id: number,
    payload: { [key: string]: any } = {},
    config: NitroFetchOptions<string> = {}
  ) {
    await $tutorings.delete(id, payload, config)
  }

  async function update (
    id: number | string,
    payload?: Record<string, any>,
    config?: NitroFetchOptions<string>
  ) {
    const { tutoring: _tutoring } =
      await $tutorings.update<{ tutoring: Tutoring }>(
        id,
        { tutoring: payload },
        config
      )
    tutoring.value = _tutoring
    return _tutoring
  }

  return {
    agreeTermsAndConditions,
    create,
    deleteTutoring,
    pendingBookingsByTutor,
    show,
    selectedTutor,
    totalPendingDurationByTutor,
    tutoring,
    tutoringPayloads,
    update
  }
})
